import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import AdPerformanceChart from '../../shared/charts/AdPerformanceChart';

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Dashboard = () => {
  const [targetSpecs, setTargetSpecs] = useState([]);
  const [filteredSpecs, setFilteredSpecs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const [openChart, setOpenChart] = useState(false);

  const theme = useTheme();

  const StyledCard = styled(Card)({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  });

  useEffect(() => {
    // Fetch target specs from API
    const fetchTargetSpecs = async () => {
      try {
        const response = await fetch("/v1/analytics/target-specs"); // Replace with your actual API endpoint
        const data = await response.json();
        setTargetSpecs(data);
        setFilteredSpecs(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching target specs:", error);
        setLoading(false);
      }
    };

    fetchTargetSpecs();
  }, []);

  useEffect(() => {
    const filtered = targetSpecs.filter(
      (spec) =>
        spec.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
        spec.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        spec.age_min.toString().includes(searchTerm) ||
        spec.age_max.toString().includes(searchTerm)
    );
    setFilteredSpecs(filtered);
  }, [searchTerm, targetSpecs]);

  const handleSpecClick = (spec) => {
    setSelectedSpec(spec);
    setOpenChart(true);
  };

  const handleCloseChart = () => {
    setOpenChart(false);
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Target Specifications Dashboard
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by gender, country, or age..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 4 }}
        />
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {filteredSpecs.map((spec) => (
              <Grid item xs={12} sm={6} md={4} key={spec.criteria_id}>
                <StyledCard>
                  <CardActionArea onClick={() => handleSpecClick(spec)}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {spec.country} - {spec.gender}
                      </Typography>
                      <Typography color="text.secondary">
                        Age: {spec.age_min} - {spec.age_max}
                      </Typography>
                      <Box mt={2}>
                        <StyledChip label={`ID: ${spec.criteria_id}`} />
                        <StyledChip label={spec.device} />
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Dialog
        open={openChart}
        onClose={handleCloseChart}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Ad Performance for {selectedSpec?.country} - {selectedSpec?.gender} (
          {selectedSpec?.age_min}-{selectedSpec?.age_max})
        </DialogTitle>
        <DialogContent>
          {selectedSpec && (
            <AdPerformanceChart criteriaId={selectedSpec.criteria_id} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Dashboard;
