// AdPerformanceChart.jsx
import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";

const AdPerformanceChart = ({ criteriaId }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const fromdate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    setFromDate(fromdate.toISOString().split("T")[0]);
    setToDate(today.toISOString().split("T")[0]);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `/v1/analytics/ad-performance/${criteriaId}?from_date=${fromDate}&to_date=${toDate}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setChartData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (criteriaId && fromDate && toDate) {
      fetchData();
    }
  }, [criteriaId, fromDate, toDate]);

  const handleDateChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ height: 550, width: '100%' }}> {/* Increased overall height */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2 }}>
        <TextField
          label="From Date"
          type="date"
          value={fromDate}
          onChange={handleDateChange(setFromDate)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="To Date"
          type="date"
          value={toDate}
          onChange={handleDateChange(setToDate)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* <Button variant="contained" onClick={handleRefresh}>
          Refresh Data
        </Button> */}
      </Box>
      {loading ? (
        <CircularProgress />
      ) : chartData ? (
        <Box sx={{ height: 450, width: '100%', mt: 2 }}> {/* Added margin top */}
          <BarChart
            xAxis={[
              { 
                scaleType: 'band', 
                data: chartData.xAxis.data,
                // label: 'Date'
              }
            ]}
            series={chartData.series.map(series => ({
              data: series.data,
              label: series.name,
              valueFormatter: (value) => value.toLocaleString()
            }))}
            width={800}
            height={400}
            yAxis={[{ 
            //   label: 'Number of Users',
              valueFormatter: (value) => value.toLocaleString()
            }]}
            margin={{ 
              top: 40,    // Increased top margin
              right: 10, 
              bottom: 30, 
              left: 100    // Increased left margin
            }}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'right' },
                    padding: 0,
                  },
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};


export default AdPerformanceChart;
